import React from 'react'

import './styles/main.sass'
import Header from './components/header/Header'
// import LandingPage from './views/landing-page/LandingPage'
import Conciliador from './views/conciliador/Conciliador'
import Adquirentes from './views/adquirentes-integradas/Adquirentes'
import Contato from './views/contato/Contato'
import QuemSomos from './views/quem-somos/QuemSomos'
import Servicos from './views/servicos/Servicos'
import SejaParceiro from './views/seja-parceiro/SejaParceiro'
import AtuacaoNacional from './views/atuacao-nacional/AtuacaoNacional'

function App() {
  return (
    <React.Fragment>
      <Header/>
      {/* <LandingPage/>   */}
      <Conciliador/>
      <Adquirentes/>
      <QuemSomos/>
      <Servicos/>
      <AtuacaoNacional/>
      <SejaParceiro/>
      <Contato/>
    </React.Fragment>
  )
}

export default App